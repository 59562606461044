




















































































import { Component, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { mixins } from 'vue-class-component';
import {
  getConfigEnv, getComponent, getView, sleep,
} from '@/utils/helpers';

import TasqDetailsMixin from '@/components/tasqsCommon/TasqDetailsMixin';
import tasqsListModule from '@/store/modules/tasqsListModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    TasqDetails: () => getComponent('tasqs/TasqDetailV2'),
    WorkTicketDetail: () => getComponent('tasqs/WorkTicketDetail'),
    AddWorkTicket: () => getComponent('tasqsCommon/AddWorkTicket'),
  },
})
export default class
TasqsDesktop extends mixins(TasqDetailsMixin) {
  showAddWorkTicket = false;

  get activeWorkTicket() {
    return tasqsListModule.activeWorkTicket;
  }
}
