import { Component, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { mixins } from 'vue-class-component';
import {
  getConfigEnv, getComponent, getView, sleep,
} from '@/utils/helpers';
import Multiselect from 'vue-multiselect';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqsListModule from '@/store/modules/tasqsListModule';
import DataLoading from '@/lib/mixins/dataLoading';
import TasqMixin from '@/lib/mixins/TasqMixin';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
// import { ALL_TASQS_LIST_ITEM, SHOW_ALERT} from '@/lib/constants';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import workflowModule from '@/store/modules/workflowModule';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import VueTagsInput from '@johmun/vue-tags-input';
import workspaceModule from '@/store/modules/workspaceModule';

import { getNameByEmail } from '@/utils/users';
import { datadogRum } from '@datadog/browser-rum';
import {
  preloadData,
} from '@/utils/preloadOfflineData';
import {
  ALL_TASQS_LIST_ITEM,
  SHOW_ALERT,
  TASQ_OFF_TARGET_TYPE,
  TASQ_WAITING_ON_LIST,
  WELL_CLICK_EVENT,
  SHOW_ENDPOINT_RESPONSE_ALERT,
} from '@/lib/constants';
import scheduleModule from '@/store/modules/scheduleModule';
import { Debounce } from 'vue-debounce-decorator';
import metaDataModule from '@/store/modules/metaDataModule';


@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    TasqFeedback: () => getComponent('tasqs/TasqFeedbackV4'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    TasqItem: () => getComponent('tasqs/TasqItem'),
    manualTasq: () => getComponent('tasqs/TasqManualPopup'),
    WellSignalsView: () => getComponent('tasqs/WellSignalsView'),
    Multiselect,
    DateRangePicker,
    VueTagsInput,
  },
})
export default class TasqsDetailsMixin extends mixins(DataLoading, TasqMixin) {
  

  isLoadingSignals = true



  tasqOptionsOpen = false;


  chartsLoading = false;

  showAddManualTasq = false;

  tag: any = '';

  tags: any = [];

  activeTableID: any = '';

  showLinkSharePopup = false;

  activeWorkspaceID: any = '';

  showChartModal: any = false;

  autoCompleteTags: any = [];

  addTasqAssetType = ''

  equipment: any = '';

  equipments: any = [];

  signalChartTime = 60;

  loadingTasqs = false;

  isOnlineApp = true;

 

  showComment = false;

  multiTasqType = '';

  subPredictionList: any = [];

  comment = '';

  visiblePadWellsFrom = 0

  visiblePadWellsTo = 5

  showActionDialog: boolean = false;

  showDataLoadingFeedback = false

  actionErrors: any = [];



  testSlowItDown = false




  //   windowWidth = window.innerWidth;
  //   get isNarrowScreen() {
  //      return this.windowWidth < 1550;
  //    }
  get showWellEvents() {
    return tasqProductionDataChartModule.showWellEvents;
  }

  get isEditing() {
	  return tasqsListModule.isEditing;
  }









  addTasqDidSelectAssetTasq() {
  }

  closeEditToolbar() {
    tasqsListModule.setIsEditing(false);
    tasqsListModule.setIsBatchResponding(false);
    tasqsListModule.setIsBatchReassign(false);
    // tasqsListModule.setActiveTasq('');
    tasqsListModule.resetChekedTasqs();
  }

  openAddTag() {
    // @ts-ignore
    document.querySelector('.customUINew input').style.background = 'transparent';
    // @ts-ignore
    document.querySelector('.customUINew input').style.maxWidth = '100px';
    const element: any = document.querySelector('.customUINew');
    const element2 = element.querySelector('input');
    if (element) {
      const dropdown = element.querySelector('.multiselect__content-wrapper');
      const cor = element.getBoundingClientRect();
      const top = cor.top - 300;
      // console.log(cor)
      dropdown.style.left = `${cor.x}px`;
    }
  }

  closeAddTag() {
    // @ts-ignore
    document.querySelector('.customUINew input').style.background = '#495364';
    // @ts-ignore
    document.querySelector('.customUINew input').style.display = 'block';
  }

 
  closeShareableDialog() {
    this.showLinkSharePopup = false;
  }

  async chartModalSetup() {
    this.showChartModal = false;
    const elements = document.querySelectorAll('.tasqs-grid')![0];
    if (elements) {
      //  @ts-ignore
      elements.style.gridTemplateColumns = 'auto 400px';
    }
  }

  get productionData() {
    return tasqProductionDataChartModule.productionData;
  }



 

  get isBatchResponding() {
	  return tasqsListModule.isBatchResponding;
  }

  async batchRespond() {
	  if (this.respondEnabled) {
    //   workflowModule.getJobResponse(tasqsListModule.checkedTasqs[0]);
      // 	  await workflowModule.getJobResponseV2(tasqsListModule.checkedTasqs[0]);
      // 	  this.showDataLoadingFeedback = false;
      // 	  // if (workflowModule.v2ResponseData != null) {
    //   //   proceduresModule.getProceduresStepsJson({
    //   // 	  jsonVersion: workflowModule.v2ResponseData.jsonVersion,
    //   //   });
      // 	  // } else {
    //   //   proceduresModule.getProceduresStepsJson({});
      // 	  // }
      // 	  tasqsListModule.setIsBatchResponding(true);
	  }
  }

  async batchReassign() {
	  if (this.respondEnabled) {
      tasqsListModule.setIsBatchReassign(true);
      accountModule.getReassignmentList({ useExisting: true });
		  this.prepareAction('reassign');
	  }
  }

  get adminUser() {
    return workflowModule.user.adminChartAccess;
  }

  async batchWait() {
	  if (this.respondEnabled) {
      tasqsListModule.setIsBatchReassign(true);
		  this.prepareAction('wait');
	  }
  }

  get getBatchEditResponseDisabledMsg() {
	  return tasqsListModule.batchResponseDisabledMsg;
  }

  get respondEnabled() {
	  return tasqsListModule.batchResponseEnabled;
  }

  get selectedEditItemsCount() {
	  return tasqsListModule.checkedTasqs.length;
  }

  padWellsSkipToEnd() {
	  this.visiblePadWellsFrom = (Math.ceil(this.activeTasq?.wells.length / 6) - 1) * 6;
	  this.visiblePadWellsTo = this.visiblePadWellsFrom + 5;
  }

  padWellsNext() {
	  if (this.visiblePadWellsTo >= this.activeTasq?.wells.length || this.visiblePadWellsFrom + 6 >= this.activeTasq?.wells.length) {
		  return;
	  }
	  this.visiblePadWellsFrom += 6;
	  this.visiblePadWellsTo += 6;
  }

  padWellsPrevious() {
	  if (this.visiblePadWellsFrom - 6 < 0) {
		  return;
	  }
	  this.visiblePadWellsFrom -= 6;
	  this.visiblePadWellsTo -= 6;
  }

  padWellsSkipToBeginning() {
    this.visiblePadWellsFrom = 0;
    this.visiblePadWellsTo = 5;
  }

  get showErrorMsgTooltip() {
	  return !this.respondEnabled && tasqsListModule.checkedTasqs.length > 0;
  }

  get tasqs(): TasqJob[] {
    return tasqsListModule.allTasqs;
  }

  get activeTasqs(): TasqJob[] {
    return tasqsListModule.tasqList;
  }

  get wellTasqs(): TasqJob[] {
    return tasqsListModule.additionalPadNestedTasqs;
  }

  get producingWells(): TasqJob[] {
    return tasqsListModule.producingWells;
  }

  get getWells() {
    let responseData: any[] = [];
	  if (this.activeTasq != null) {
		  // @ts-ignore
      if (this.routeQueryView === 'wellview' && this.tasqListLevel.toLowerCase() === 'well') {
        console.log('dkfjajsdfjklasdfkl');
        const newResponseData: any = this.activeTasqs.filter((well) => well.wellName === this.activeTasq?.wellName);
	  if (responseData) {
          responseData = newResponseData.concat(responseData);
	  }
      } else if (this.tasqListLevel.toLowerCase() === 'pad') {
        for (let r = 0; r < this.activeTasq.wells.length; r++) {
			  // @ts-ignore
          //   if (this.activeTasq.wells[r].predictionType != "Producing") {
          if (this.activeTasq.wells[r].id) {
				  // @ts-ignore
				  responseData.push(this.activeTasq.wells[r]);
          }
          //   }
		  }
      } else if (this.tasqListLevel.toLowerCase() === 'wellview') {
        // for (let x = 0; x < this.producingWells.length; x++) {
        //   if (this.activeTasq.wellName != null && this.producingWells[x].wellName == this.activeTasq.wellName) {
        //     responseData.push(this.producingWells[x]);
        //   }
        // }
	  const newResponseData: any = this.activeTasqs.filter((well) => well.wellName === this.activeTasq?.wellName);
	  if (responseData) {
          responseData = newResponseData.concat(responseData);
	  }
      }
	  }
    // console.log(responseData)
	  return responseData;
    //   if (this.showAllWells) {
    // 	  // @ts-ignore
    // 	  return this.tasq.wells
    //   } else {
    //   }
  }

  get tasqListLevel() {
	  if (tasqsListModule.tasqListLevel.toLowerCase() == 'well' || (this.activeTasq && tasqsListModule.tasqListLevel.toLowerCase() !== 'wellview' && this.activeTasq.level.toLowerCase() == 'well')) {
		  return 'Well';
	  }
	  return tasqsListModule.tasqListLevel.toLowerCase();
    //   if (this.activeTasq == null) {
    // 	  return 'WELL';
    //   }
    //   return this.activeTasq?.level;
  }

  get isProductionDataLoaded() {
    return tasqProductionDataChartModule.productionData.loaded;
  }

  get currentWellType(): any {
	  return this.$route.query.type;
  }




  get currentSignals(): any {
    return tasqSignalsModule.currentSignals;
  }

 

  get productionDataDict(): any {
    return tasqProductionDataChartModule.productionDataDict;
  }



  async tagsAdded(newTag) {
    this.tags.push(newTag.toLowerCase());
    //  newTags.push(newTag)
    await scheduleModule.addTagsToWell({ node_id: this.activeTasq?.wellName, tags: this.tags });
  }

  async tagsChanged(newTag) {
  //  let newTags = this.tags;
    //  newTags.push(newTag)
    await scheduleModule.addTagsToWell({ node_id: this.activeTasq?.wellName, tags: [newTag.toLowerCase()].concat(this.tags) });
  }

  async tagsRemove(removeT) {
    const index = this.tags.indexOf(removeT);
    if (index > -1) {
      this.tags.splice(index, 1);
    }
    await scheduleModule.addTagsToWell({ node_id: this.activeTasq?.wellName, tags: this.tags });
  }

  get routeQueryType() {
    return this.$route.query.type;
  }

  get routeQueryView() {
    return this.$route.query.view;
  }

  getPredictionList() {
    this.subPredictionList = [];
    if (this.activeTasq && this.activeTasq.predictionsList && Object.keys(this.activeTasq.predictionsList) && this.routeQueryType !== 'producing') {
      this.activePredictionItem = this.activeTasq.id;
      let found = false;
      this.subPredictionList = Object.keys(this.activeTasq.predictionsList).map((data) => {
        const object:any = this.activeTasq?.predictionsList[data];
        if (data === this.activeTasq?.overriddenName) {
          found = true;
        }
        if (data === 'Off-Target RT') {
          data = 'REAL-Time';
        }
        return { name: data, predictionId: object.PredictionID, color: this.bgColorClass(data) };
      });
      if (!found) {
        this.subPredictionList.push({ name: this.activeTasq.engineerType, predictionId: this.activeTasq.id, color: this.bgColorClass(this.activeTasq.overriddenName) });
      }
    } else {
      this.subPredictionList = [];
    }
  }

  get activeTasq() {
    if (this.isEditing || tasqsListModule.checkedTasqs.length) {
		  if (!tasqsListModule.activeTasq) {
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
		  return tasqsListModule.activeTasq as TasqJob;
	  }
    if (this.$route.query.type == 'id' && this.$route.params.id != null) {
      assetsModule.setActiveProducingTasq('');
      return tasqsListModule.activeTasq as TasqJob;
    }
    if (tasqsListModule.activeTasq != null && this.$route.params.id != null && this.$route.query.type == 'producing' && tasqsListModule.activeTasq.level.toLowerCase() == 'pad') {
	  return tasqsListModule.activeTasq;
    }
    if (assetsModule.activeTasq == undefined) {
      return tasqsListModule.activeTasq  as TasqJob;
    }
    return assetsModule.activeTasq  as TasqJob;
  }

  get reassignUsers() {
    return accountModule.reassignmentList.map((i) => ({
      // @ts-ignore
      text: i.name,
      value: i.email,
    })).sort((a, b) => a.text.localeCompare(b.text));
  }

  get enabledPads() {
    return assetsModule.enabledPads;
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get enabledWellsMapping() {
    return assetsModule.enabledWells.map((well) => ({ text: well.text, value: well.value }));
  }

  resetTasq() {
    tasqFeedbackModule.resetAllData();
  }

  @Debounce(1000)
  showEventCommentPopup(comment) {
    console.log(comment);
    if (comment) {
      this.wellEventDetails = {

        createdBy: comment.Username ? getNameByEmail(comment.Username) : 'N/A',
        createdDate: comment.time,
        ...comment,
        jobType: comment.JobType || comment.PredictionType,
      };
      this.showWellEventDetail = true;
    }
  }

  get waitingOnOptions() {
    return metaDataModule.waitingOnList;
  }

  localHistoryEvent = true;

  async created() {
   
    await metaDataModule.getActionsBuilder();
    tasqsListModule.setActivePage('Tasq');


    this.isOnlineApp = navigator.onLine;
    this.sleep(5000).then(() => {
      const promiseList: any = [];
      // promiseList.push(assetsModule.getEnabledPads());
      promiseList.push(metaDataModule.getActionsBuilder());
      promiseList.push(accountModule.getReassignmentList({ useExisting: true }));
      promiseList.push(assetsModule.getEnabledWells());
      Promise.all(promiseList).then(async () => {
        // console.log('resolved');
        if (this.isOnlineApp) {
          workspaceModule.getWorkspaces(null);
          if (!accountModule.didPreloadData) {
            // await preloadData();
          }
          datadogRum.init({
            applicationId: '42a5cbbe-cee7-4b16-b31c-27f8330f507b',
            clientToken: 'pubb848c6a76f8811fde5cd24dc0264f13f',
            site: 'datadoghq.com',
            service: 'tasq',
            env: getConfigEnv('BRANCH_NAME'),
            // Specify a version number to identify the deployed version of your application in Datadog
            version: accountModule.user.email,
            sampleRate: 100,
            premiumSampleRate: 100,
            trackInteractions: true,
            defaultPrivacyLevel: 'allow',
          });
          datadogRum.startSessionReplayRecording();
        }
        // await tasqsListModule.getTasqListResponses();
      }, (err) => {
        console.log('Error:');
        console.log(err);
      });
    });
    this.loadingTasqs = true;
  }

  async fetchTasqAsync() {
    if (this.$route.params.id != null && this.$route.params.id != '' && this.$route.query.type == 'id') {
      await this.fetchTasq();
    }
  }

  beforeDestroy() {
    tasqsListModule.setActiveTasq('');
    this.$eventBus.$off('history-search-updated')
  }

  editTasqs() {
    tasqsListModule.setIsLoadingTasqResponses(false);
	  if (tasqsListModule.isEditing) {
		  this.closeEditToolbar();
	  } else {
		  tasqsListModule.setActiveTasq('');
		  tasqsListModule.setIsEditing(true);
	  }
  }

  activePredictionItem: any = ''

  async updateTasqPrediction(data) {
    assetsModule.setActiveProducingTasq('');
    window.history.pushState('tasqs', 'Title', `/tasqs/${data.predictionId}?type=id`);
    this.multiTasqType = data.name;
    this.showDataLoadingFeedback = true;
    workflowModule.setV2ResponseData(null);
    tasqFeedbackModule.resetAllData();
    this.activePredictionItem = data.predictionId;
    await this.fetchTasq(data.predictionId, true);
    this.showDataLoadingFeedback = false;
  }

  editWaitingOnStatus() {
    if (this.activeTasq && this.activeTasq.waitingOnStartDate && this.activeTasq.waitingOnEndDate) {
    // console.log(this.activeTasq.snoozed.WaitStartDate);
      this.action.type = 'wait';
      this.action.startDate = this.activeTasq?.snoozed.WaitStartDate;
      this.action.date = this.activeTasq?.snoozed.UntilDate;
      if (this.activeTasq.waitUntilReason) {
        this.action.reason = this.activeTasq?.waitUntilReason.split('-Remarks:')[0];
      }
      // this.action.comment = this.activeTasq.waitUntilReason.split('-')[1];
      this.action.title = `Please select status for ${this.activeTasq?.wellName}:`;
      this.action.confirmText = 'Update';
      this.$dialog.show('actionModal');
    }
  }

  prepareAction(action) {
    this.showActionDialog = true;
    this.action = {
      type: '',
      title: '',
      confirmText: '',
      reason: '',
      person: '',
      comment: '',
      date: '',
      well: '',
      startDate: new Date(),
      jobType: '',
      frequency: 'Today',
      delayedDays: 0,
    };
    switch (action) {
      case ('reject'):
        this.action.type = action;
        this.action.title = `Are you sure you want to reject ${this.activeTasq?.wellName}?`;
        this.action.confirmText = 'Yes, reject it';
        this.$dialog.show('actionModal');
        break;
      case ('reassign'):
        this.action.type = action;
        this.action.title = `Select the user to reassign ${this.activeTasq?.wellName}:`;
        this.action.confirmText = 'Reassign tasq';
        this.$dialog.show('actionModal');
        break;
      case ('wait'):
        this.action.type = action;
        this.action.title = `Please select status for ${this.activeTasq?.wellName}:`;
        this.action.confirmText = 'Update';
        this.$dialog.show('actionModal');
        break;
      case ('add'):
        this.showAddManualTasq = true;
        break;
      default:
        break;
    }
  }

  openDialog() {
    this.sleep(300).then(() => {
      this.$dialog.show('actionModal');
    });
  }

  validateDialog(type) {
    if (type === 'reassign') {
      if (!this.action.person) {
        this.actionErrors.push('person');
      }
    } else if (type === 'wait') {
      if (!this.action.date) {
        this.actionErrors.push('date');
      }
      if (!this.action.startDate) {
        this.actionErrors.push('startDate');
      }
      if (!this.action.reason) {
        this.actionErrors.push('reason');
      }
      if (this.action.date && this.action.startDate && this.action.date < this.action.startDate) {
        this.actionErrors.push('invalidEndDate');
      }
    } else if (type === 'reject') {
      if (!this.action.reason) {
        this.actionErrors.push('reason');
      }
    } else if (type === 'add') {
      if (!this.addTasqAssetType) {
        this.actionErrors.push('addTasqAssetType');
      } else {
        if (!this.action.jobType) {
          this.actionErrors.push('jobType');
        }
        if (!this.action.person) {
          this.actionErrors.push('person');
        }
        if (!this.action.well) {
          this.actionErrors.push('well');
        }
      }
    }
  }

  async onActionDialogClosed(response) {
    let successText = ''!;
    this.actionErrors = [];
    const tasqID = this.activeTasq?.id;
    // this.startDataLoading();
    if (response.isOk) {
      this.validateDialog(this.action.type);
      if (this.actionErrors.length) {
        this.openDialog();
        return;
      }
      switch (this.action.type) {
        case ('reassign'):
          successText = 'Successfully reassigned!';
          await tasqActionsModule.reassignTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('reject'):
          successText = 'Successfully Rejected!';
          await tasqActionsModule.rejectTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('wait'):
          successText = 'Successfully added to waitlist!';
          await tasqActionsModule.updateTasqStatus(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('add'):
          successText = 'Tasq added successfully!';
		  this.action.assetType = this.addTasqAssetType;
          await tasqActionsModule.addTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        default:
          break;
      }
      this.closeEditToolbar();
      this.action = {};
      this.showActionDialog = false;
      this.$router.push({ name: 'Tasqs' });
      await sleep(3000);
      const updatedTasq = await tasqsListModule.getRawTasq({ PredictionID: tasqID });
      console.log(updatedTasq);
      //   this.stopDataLoading();
      if (successText) {
        this.$eventBus.$emit(SHOW_ALERT, successText);
      }
    }
  }

  async snoozeTasqForToday() {
    const tasqID = this.activeTasq?.id;
    this.startDataLoading();
    const action = {
      reason: 'Snoozed',
      comment: '',
      date: new Date().setDate(new Date().getDate() + 1),
      id: this.activeTasq?.id,
      startDate: new Date(),
    };
    const successText = 'Successfully snoozed!';
    this.snoozeTasqPopup = false;
    await tasqActionsModule.updateTasqStatus(action);
    this.$router.push({ name: 'Tasqs' });
    await sleep(2000);
    await tasqsListModule.getRawTasq({ PredictionID: tasqID });
    this.stopDataLoading();
    if (successText) {
      this.$eventBus.$emit(SHOW_ALERT, successText);
    }
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

 

  

  async fetchTasq(predictionID = null, loadingFromSubPrediction = false) {
    // this.chartModalSetup();
 
    this.showDataLoadingFeedback = true;

    tasqsListModule.setTasqEventHistoryList([]);
    workflowModule.setWellHistory([]);
    this.signalChartTime = 60;
    if (!loadingFromSubPrediction) {
      this.activePredictionItem = '';
  
    }

    this.isOnlineApp = navigator.onLine;
    workflowModule.setV2ResponseData(null);
    tasqsListModule.setFromNowBack(0);
  
    if (predictionID != null) {
      // @ts-ignore
      if (!loadingFromSubPrediction) {
        this.chartsLoading = true;
        // @ts-ignore
        tasqsListModule.setActiveTasq(predictionID);
        if (tasqsListModule.activeTasq == null) {
          if (this.tasqListLevel.toLowerCase() !== 'pad') {
            await tasqsListModule.getTasq({
              PredictionID: this.$route.params.id,
            });
          }
        }
      }
    } else if (this.$route.params.id != null && this.$route.params.id != '' && this.$route.query.type == 'id') {
      if (!this.isOnlineApp) {
        await tasqsListModule.setActiveTasq(this.$route.params.id);
      } else if (this.tasqListLevel.toLowerCase() !== 'pad') {
        await tasqsListModule.getTasq({
			  PredictionID: this.$route.params.id,
		  });
      }
    } else {
      assetsModule.setActiveProducingTasq(this.$route.params.id);
    }

    this.showDataLoadingFeedback = false;
    if (this.activeTasq && this.activeTasq.level.toLowerCase() == 'well') {
      if (!loadingFromSubPrediction) {
        if (this.activeTasq != null) {
          const historyPromises: any = [];
          historyPromises.push(workflowModule.getWellHistory(this.activeTasq?.wellName));
          if (this.activeTasq != null && (this.currentWellType != 'producing')) {
            historyPromises.push(tasqsListModule.getTasqEventHistoryList(this.activeTasq?.workflowTasqId));
          }

          // this.showWellEventsLocal = !this.showWellEventsLocal;

          Promise.all(historyPromises).then(async () => {
            // this.showWellEventsLocal =  !this.showWellEventsLocal;
          }, (err) => {
            this.$eventBus.$emit(SHOW_ENDPOINT_RESPONSE_ALERT, { text: 'It is not possible to load well history at this moment, please try later', error: true });
            console.log('Error:');
            console.log(err);
          });
        }
      } else {
        await workflowModule.getWellHistory(this.activeTasq?.wellName);
      }
    }
  }

  goToTasqPage(tasq, view = 'tasq') {
    this.$router.push({
      name: 'Tasqs',
      params: {
        id: tasq?.id || '',
      },
      query: { type: 'id', view },
    });
	  tasqsListModule.setIsEditing(false);
    assetsModule.setActiveProducingTasq('');
    tasqsListModule.setActiveTasq(tasq?.id || '');
  }


  get getWellHistory() {
    return workflowModule.wellHistory;
  }

  get isHistorySectionOpen() {
    return tasqsListModule.isHistorySectionOpen
  }

}
